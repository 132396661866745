<template>
  <div class="video">
    <vue-plyr ref="plyr" :options="options">
      <template v-if="videoType === 'html5'">
        <slot/>
      </template>

      <div v-else :data-plyr-provider="videoType" :data-plyr-embed-id="videoKey"></div>
    </vue-plyr>
  </div>
</template>

<script>
import VuePlyr from 'vue-plyr'

export default {
  name: 'Video',
  components: {
    VuePlyr
  },
  props: {
    videoType: {
      type: String,
      default: 'html5'
    },
    videoKey: {
      type: String,
      default: null
    },
    // visit: https://github.com/sampotts/plyr#options
    plyrOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      options: Object.assign({
        controls: [
          // 'play-large',
          'play',
          'progress',
          'current-time',
          'mute',
          'volume',
          'captions',
          // 'settings',
          // 'pip',
          'airplay',
          'fullscreen'
        ],
        youtube: {
          modestbranding: 1,
          rel: 0
        }
      }, this.plyrOptions)
    }
  }
}
</script>

<style lang="scss">
@import '~vue-plyr/dist/vue-plyr.css';

$video-margin-bottom: $spacer !default;

$video-color-main: $primary !default;
$video-video-background: rgba($black, 0.1) !default;

$video-badge-background: $dark !default;
$video-badge-text-color: $white !default;
$video-badge-border-radius: $border-radius-sm !default;

$video-tab-focus-color: rgba($video-color-main, 0.4) !default;
$video-tab-focus-outline-width: 2px !default;

$video-captions-background: rgba($black, 0.8) !default;
$video-captions-text-color: $white !default;

$video-control-icon-size: $font-size-sm !default;
$video-control-spacing: $form-grid-gutter-width !default;
$video-control-padding: $video-control-spacing * 0.7 !default;
$video-control-radius: $border-radius-sm !default;
$video-control-toggle-checked-background: $video-color-main !default;

$video-controls-padding: 5px 10px !default;
$video-video-controls-background: linear-gradient(rgba($black, 0), rgba($black, 0.75)) !default;
$video-video-control-color: $white !default;
$video-video-control-color-hover: $video-video-control-color !default;
$video-video-control-background-hover: $video-color-main !default;

$video-audio-controls-background: $white !default;
$video-audio-control-color: $dark !default;
$video-audio-control-color-hover: $white !default;
$video-audio-control-background-hover: $video-color-main !default;

$video-menu-background: rgba($white, 0.9) !default;
$video-menu-color: $dark !default;
$video-menu-shadow: 0 1px 2px rgba($black, 0.15) !default;
$video-menu-radius: $border-radius-sm !default;
$video-menu-arrow-size: 6px !default;
$video-menu-item-arrow-color: $dark !default;
$video-menu-item-arrow-size: 4px !default;
$video-menu-border-color: $border-color !default;
$video-menu-border-shadow-color: $white !default;
$video-menu-radio-size: 14px !default;
$video-menu-radio-checked-size: 4px !default;

$video-progress-loading-size: 25px !default;
$video-progress-loading-background: rgba($dark, 0.5) !default;
$video-video-progress-buffered-background: rgba($white, 0.25) !default;
$video-audio-progress-buffered-background: rgba($white, 0.5) !default;

$video-range-thumb-height: 12px !default;
$video-range-thumb-background: $white !default;
$video-range-thumb-shadow: 0 0 0 0 rgba($video-range-thumb-background, 0.3) !default;
$video-range-thumb-active-shadow-width: 2px !default;
$video-range-track-height: 4px !default;
$video-range-fill-background: $video-color-main !default;

$video-video-range-track-background: $video-video-progress-buffered-background !default;
$video-video-range-thumb-active-shadow-color: rgba($white, 0.5) !default;
$video-audio-range-track-background: $video-audio-progress-buffered-background !default;
$video-audio-range-thumb-active-shadow-color: rgba($white, 0.5) !default;

$video-tooltip-background: rgba($white, 0.9) !default;
$video-tooltip-color: $dark !default;
$video-tooltip-padding: $video-control-spacing * 0.25 !default;
$video-tooltip-arrow-size: $video-menu-arrow-size !default;
$video-tooltip-radius: $border-radius-sm !default;
$video-tooltip-shadow: 0 1px 2px rgba($black, 0.15) !default;

$video-font-family: $font-family-base !default;
$video-font-size-base: $font-size-sm !default;
$video-font-size-small: $video-font-size-base * 0.85 !default;
$video-font-size-large: $video-font-size-base * 1.1 !default;
$video-font-size-xlarge: $font-size-lg * 1.1 !default;
$video-font-size-time: $video-font-size-small !default;
$video-font-size-menu: $video-font-size-small !default;
$video-font-size-badge: $video-font-size-small !default;
$video-font-weight-regular: $font-weight-normal !default;
$video-font-weight-bold: $font-weight-bold !default;
$video-line-height: $line-height-base !default;
$video-font-smoothing: false !default;

.video {
  margin-bottom: $video-margin-bottom;

  .plyr {
    --plyr-color-main: #{$video-color-main};
    --plyr-video-background: #{$video-video-background};
    --plyr-tab-focus-color: #{$video-tab-focus-color};
    --plyr-badge-background: #{$video-badge-background};
    --plyr-badge-text-color: #{$video-badge-text-color};
    --plyr-badge-border-radius: #{$video-badge-border-radius};
    --plyr-tab-focus-color: #{$video-tab-focus-color};
    --plyr-captions-background: #{$video-captions-background};
    --plyr-captions-text-color: #{$video-captions-text-color};
    --plyr-control-icon-size: #{$video-control-icon-size};
    --plyr-control-spacing: #{$video-control-spacing};
    --plyr-control-padding: #{$video-control-padding};
    --plyr-control-radius: #{$video-control-radius};
    --plyr-control-toggle-checked-background: #{$video-control-toggle-checked-background};
    --plyr-video-controls-background: #{$video-video-controls-background};
    --plyr-video-control-color: #{$video-video-control-color};
    --plyr-video-control-color-hover: #{$video-video-control-color-hover};
    --plyr-video-control-background-hover: #{$video-video-control-background-hover};
    --plyr-audio-controls-background: #{$video-audio-controls-background};
    --plyr-audio-control-color: #{$video-audio-control-color};
    --plyr-audio-control-color-hover: #{$video-audio-control-color-hover};
    --plyr-audio-control-background-hover: #{$video-audio-control-background-hover};
    --plyr-menu-background: #{$video-menu-background};
    --plyr-menu-color: #{$video-menu-color};
    --plyr-menu-shadow: #{$video-menu-shadow};
    --plyr-menu-radius: #{$video-menu-radius};
    --plyr-menu-arrow-size: #{$video-menu-arrow-size};
    --plyr-menu-item-arrow-color: #{$video-menu-item-arrow-color};
    --plyr-menu-item-arrow-size: #{$video-menu-item-arrow-size};
    --plyr-menu-border-color: #{$video-menu-border-color};
    --plyr-menu-border-shadow-color: #{$video-menu-border-shadow-color};
    --plyr-progress-loading-size: #{$video-progress-loading-size};
    --plyr-progress-loading-background: #{$video-progress-loading-background};
    --plyr-video-progress-buffered-background: #{$video-video-progress-buffered-background};
    --plyr-audio-progress-buffered-background: #{$video-audio-progress-buffered-background};
    --plyr-range-thumb-height: #{$video-range-thumb-height};
    --plyr-range-thumb-background: #{$video-range-thumb-background};
    --plyr-range-thumb-shadow: #{$video-range-thumb-shadow};
    --plyr-range-thumb-active-shadow-width: #{$video-range-thumb-active-shadow-width};
    --plyr-range-track-height: #{$video-range-track-height};
    --plyr-range-fill-background: #{$video-range-fill-background};
    --plyr-video-range-track-background: #{$video-video-range-track-background};
    --plyr-video-range-thumb-active-shadow-color: #{$video-video-range-thumb-active-shadow-color};
    --plyr-audio-range-track-background: #{$video-audio-range-track-background};
    --plyr-audio-range-thumb-active-shadow-color: #{$video-audio-range-thumb-active-shadow-color};
    --plyr-tooltip-background: #{$video-tooltip-background};
    --plyr-tooltip-color: #{$video-tooltip-color};
    --plyr-tooltip-padding: #{$video-tooltip-padding};
    --plyr-tooltip-arrow-size: #{$video-tooltip-arrow-size};
    --plyr-tooltip-radius: #{$video-tooltip-radius};
    --plyr-tooltip-shadow: #{$video-tooltip-shadow};
    --plyr-font-family: #{$video-font-family};
    --plyr-font-size-base: #{$video-font-size-base};
    --plyr-font-size-small: #{$video-font-size-small};
    --plyr-font-size-large: #{$video-font-size-large};
    --plyr-font-size-xlarge: #{$video-font-size-xlarge};
    --plyr-font-size-time: #{$video-font-size-time};
    --plyr-font-size-menu: #{$video-font-size-menu};
    --plyr-font-size-badge: #{$video-font-size-badge};
    --plyr-font-weight-regular: #{$video-font-weight-regular};
    --plyr-font-weight-bold: #{$video-font-weight-bold};
    --plyr-line-height: #{$video-line-height};
    --plyr-font-smoothin: #{$video-font-smoothing};

    .plyr__controls {
      padding: $video-controls-padding;
    }

    .plyr__control {
      outline: $video-tab-focus-outline-width none $video-tab-focus-color;
      outline-offset: 0;

      &.plyr__tab-focus {
        outline-style: solid;
      }

      &[role="menuitemradio"] {
        &:before {
          width: $video-menu-radio-size;
          height: $video-menu-radio-size;
        }

        &:after {
          width: $video-menu-radio-checked-size;
          height: $video-menu-radio-checked-size;
        }
      }
    }

    input[type="range"]::-webkit-slider-runnable-track {
      outline: $video-tab-focus-outline-width none $video-tab-focus-color;
      outline-offset: 0;
    }
    input[type="range"].plyr__tab-focus::-webkit-slider-runnable-track {
      outline-style: solid;
    }

    input[type="range"]::-moz-range-track {
      outline: $video-tab-focus-outline-width none $video-tab-focus-color;
      outline-offset: 0;
    }
    input[type="range"].plyr__tab-focus::-moz-range-track {
      outline-style: solid;
    }
  }
}
</style>
